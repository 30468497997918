// REELDECK
(() => {
  const reelDeck = document.querySelector('.tc_deck__reel');

  if (reelDeck && !reelDeck.classList.contains('js-processed')) {

    // try runnning videoloop function from VideoLoop.vanilla.js
    // in case reel deck was loaded asynchronously and
    // the videoloop script already has run.
    typeof videoloop === 'function' && videoloop();

    reelDeck.classList.add('js-processed');
  }
})();
